import * as React from 'react';
import {
  useCopyPricebook as useCopyPricebookItem,
  useMovePricebookItems,
  usePricebookItemStatus,
} from '@sity-ai/api';

import type { PricebookItem } from '@sity-ai/types';
import { logger } from '@/lib/default-logger';
import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { usePricebookItems } from '@/hooks/use-pricebook-items';
import { Loading } from '@/components/core/loading';
import { toast } from '@/components/core/toaster';

interface PricebookItemContextValue {
  copyItem: (itemId: number) => Promise<PricebookItem>;
  error: Error | null;
  loading: boolean;
  moveItems: (selectedItemIds: number[], targetPricebookId: number) => Promise<void>;
  pricebookItems: PricebookItem[];
  refreshPricebookItems: () => void;
  updateItemsStatus: (pricebookId: number, active: boolean) => Promise<void>;
}

interface PricebookItemsProviderProps {
  children: React.ReactNode;
  pricebookId?: number;
}

const PricebookItemsContext = React.createContext<PricebookItemContextValue>({
  copyItem: async () => {
    throw new Error('Not implemented');
  },
  error: null,
  loading: false,
  moveItems: async () => undefined,
  pricebookItems: [],
  refreshPricebookItems: async () => undefined,
  updateItemsStatus: async () => undefined,
});

export function PricebookItemsProvider({
  children,
  pricebookId: initialPricebookId = 0,
}: PricebookItemsProviderProps): React.JSX.Element {
  const {
    error: pricebookError,
    loading,
    pricebookItems,
    refreshPricebookItems,
  } = usePricebookItems([initialPricebookId]);
  const { token, user } = useUserContext();

  const { mutate: movePricebookItems } = useMovePricebookItems({
    onSuccess: () => {
      refreshPricebookItems();
      toast.success('Items moved successfully');
    },
    onError: (error: Error) => {
      toast.error('Failed to move items');
      refreshPricebookItems();
    },
  });

  const { mutate: copyPricebookItem } = useCopyPricebookItem({
    onSuccess: () => {
      refreshPricebookItems();
      toast.success('Item copied successfully');
    },
    onError: (error: Error) => {
      toast.error('Failed to copy item');
      refreshPricebookItems();
    },
  });

  const { mutate: updatePricebookItemsStatus } = usePricebookItemStatus({
    onSuccess: () => {
      refreshPricebookItems();
      toast.success('Items status updated successfully');
    },
    onError: () => {
      toast.error('Failed to update items status');
      refreshPricebookItems();
    },
  });

  const updateItemsStatus = React.useCallback(
    async (targetPricebookId: number, active: boolean) => {
      await updatePricebookItemsStatus(
        {
          pricebookID: targetPricebookId.toString(),
          companyID: user?.companyID?.toString() || '',
          isActive: active,
        },
        token
      );
      refreshPricebookItems();
    },
    [refreshPricebookItems, token, user?.companyID]
  );

  const moveItems = React.useCallback(
    async (selectedItemIds: number[], targetPricebookId: number) => {
      await movePricebookItems(
        {
          itemIds: selectedItemIds,
          targetPricebookId: targetPricebookId.toString(),
          companyID: user?.companyID?.toString() || '',
        },
        token
      );
      refreshPricebookItems();
    },
    [refreshPricebookItems, token, user?.companyID]
  );

  const copyItem = React.useCallback(
    async (itemId: number): Promise<PricebookItem> => {
      if (!user?.companyID || !user?.userID) {
        throw new Error('Missing required user data');
      }

      const newItem = await copyPricebookItem(
        {
          itemId,
          companyID: user.companyID.toString(),
          userId: user.userID,
        },
        token
      );
      refreshPricebookItems();
      return newItem;
    },
    [refreshPricebookItems, token, user?.companyID, user?.userID]
  );

  const contextValue = React.useMemo(() => ({
    copyItem,
    error: pricebookError,
    loading,
    moveItems,
    pricebookItems,
    refreshPricebookItems,
    updateItemsStatus,
  }), [copyItem, pricebookError, loading, moveItems, pricebookItems, refreshPricebookItems, updateItemsStatus]);

  return (
    <PricebookItemsContext.Provider value={contextValue}>
      {loading ? <Loading /> : children}
    </PricebookItemsContext.Provider>
  );
}

export function usePricebookItemsContext(): PricebookItemContextValue {
  const context = React.useContext(PricebookItemsContext);
  if (!context) {
    throw new Error('usePricebookItemsContext must be used within a PricebookItemsProvider');
  }
  return context;
}

export { PricebookItemsContext };
