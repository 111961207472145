
import * as React from 'react'; // Add React import
import { createContext, useContext, useMemo, type ReactNode } from 'react';
import type { Dayjs } from 'dayjs';

import { dayjs } from '@/lib/dayjs';

interface SchedulingConfig {
  defaultDuration: number; // in minutes
  defaultStartTime: string;
  defaultEndTime: string;
  minDate: Dayjs;
  maxDate: Dayjs;
  timeFormat: '12h' | '24h';
  timeStep: number; // in minutes
  allowPastDates: boolean;
}

interface SchedulingContextValue {
  config: SchedulingConfig;
}

const DEFAULT_CONFIG: SchedulingConfig = {
  defaultDuration: 60,
  defaultStartTime: '09:00',
  defaultEndTime: '10:00',
  minDate: dayjs(),
  maxDate: dayjs().add(1, 'year'),
  timeFormat: '12h',
  timeStep: 30,
  allowPastDates: false,
};

const SchedulingContext = createContext<SchedulingContextValue | null>(null);

export function SchedulingProvider({
  children,
  config = DEFAULT_CONFIG,
}: {
  children: ReactNode;
  config?: Partial<SchedulingConfig>;
}): JSX.Element {
  // Add return type
  const value = useMemo(
    () => ({
      config: { ...DEFAULT_CONFIG, ...config },
    }),
    [config]
  );

  return <SchedulingContext.Provider value={value}>{children}</SchedulingContext.Provider>;
}

export function useSchedulingContext(): SchedulingContextValue {
  // Add return type
  const context = useContext(SchedulingContext);
  if (!context) {
    throw new Error('useSchedulingContext must be used within SchedulingProvider');
  }
  return context;
}
