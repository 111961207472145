'use client';

import * as React from 'react';
import { StripeCustomerSubscription, useCreateStripeSession } from '@sity-ai/api';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Star as StarIcon } from '@phosphor-icons/react/Star';
import { logger } from '@/lib/default-logger';
import { toast } from '@/components/core/toaster';

export enum Reason {
  Denied = 'denied',
  Upgrade = 'upgrade',
  Expired = 'expired',
  PaymentIssue = 'payment-issue',
}
interface PaywallProps {
  open: boolean;
  onClose: () => void;
  reason: Reason;
  subscription: StripeCustomerSubscription | null;
}

function Paywall({ open, onClose, reason, subscription }: PaywallProps): React.JSX.Element {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { mutate: createStripeSession } = useCreateStripeSession({
    onSuccess: (stripeSession: { url: string }) => {
      window.location.href = stripeSession.url;
    },
    onError: (error: Error) => {
      logger.error(error);
      toast.error('Failed to create Stripe session');
    },
  });

  const redirectToStripe = async (): Promise<void> => {
    if (!subscription) return;
    await createStripeSession({ customer: subscription.customer.id });
  };

  const images = {
    [Reason.Denied]: { alt: Reason.Denied, src: '/assets/denied.svg' },
    [Reason.Upgrade]: { alt: Reason.Upgrade, src: '/assets/upgrade.svg' },
    [Reason.Expired]: { alt: Reason.Expired, src: '/assets/expired.svg' },
    [Reason.PaymentIssue]: { alt: Reason.PaymentIssue, src: '/assets/payment.svg' },
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Grid
        container
        sx={[
          {
            backgroundColor: theme.palette.common.white,
            height: '100vh',
            overflowY: 'scroll',
            width: '100vw',
          },
          isMobileScreen
            ? {
                paddingX: 3,
              }
            : {
                paddingX: 15,
              },
          isMobileScreen
            ? {
                paddingY: 5,
              }
            : {
                paddingY: 15,
              },
        ]}
      >
        <Grid md={5} pr={isMobileScreen ? 0 : 3} xs={12}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={1}>
              <StarIcon fill={theme.palette.primary.main} weight="fill" />
              <Typography variant="subtitle2">Unlock something important</Typography>
            </Stack>
            <Typography variant="h4">Your free trial has expired</Typography>
            <Typography color="text.secondary">You no longer have access to Fieldsity.</Typography>
            <Typography color="text.secondary">
              {`Don't go back to manual processes using pen and paper. Become a member today and start saving time and
              money while helping your business move forward.`}
            </Typography>
            <Button onClick={redirectToStripe} variant="contained">
              Continue and subscribe now
            </Button>
            {/* <Button variant="outlined">Talk with us first</Button> */}
          </Stack>
        </Grid>
        <Grid md={7} pl={isMobileScreen ? 0 : 3} pt={isMobileScreen ? 4 : 0} xs={12}>
          <Stack alignContent="center" alignItems="center" justifyContent="center" justifyItems="center">
            {/* eslint-disable-next-line jsx-a11y/alt-text -- alt prop included in `images` */}
            <img {...images[reason]} style={{ maxWidth: '100%' }} />
          </Stack>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default Paywall;
