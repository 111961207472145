'use client';

import * as React from 'react';

import type { Invoice } from '@sity-ai/types';
import { useInvoices } from '@/hooks/use-invoices';

// Types
export interface InvoicesContextValue {
  invoices: Invoice[];
  refreshInvoiceList: () => void;
}

export interface InvoicesProviderProps {
  children: React.ReactNode;
}

// Context Exports
export const InvoicesContext = React.createContext<InvoicesContextValue>({
  invoices: [],
  refreshInvoiceList: () => undefined,
});

export function useInvoicesContext(): InvoicesContextValue {
  return React.useContext(InvoicesContext);
}

export function InvoicesProvider({ children }: InvoicesProviderProps): React.JSX.Element {
  const { invoices, refreshInvoiceList } = useInvoices();
  return <InvoicesContext.Provider value={{ invoices, refreshInvoiceList }}>{children}</InvoicesContext.Provider>;
}
