import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import type { Company } from '@sity-ai/types';
import { useGetCompany, useGetCompanies, useUpdateCompany } from '@sity-ai/api';
import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { logger } from '@/lib/default-logger';

interface CompaniesResponse {
  companies: Company[];
  loading: boolean;
  error: Error | null;
  refresh: () => void;
}

interface CompanyResponse {
  company: Company | null;
  loading: boolean;
  error: Error | null;
  refresh: () => void;
  update: (newCompany: Company) => void;
}

export function useCompanies(query: string): CompaniesResponse {
  const { token } = useUserContext();

  // Use last load time to invalidate cache
  const initialLoadTime = new Date().getTime();
  const [lastLoaded, setLastLoaded] = React.useState<number>(initialLoadTime);

  // Fetch data for all companies
  const { data: companies, isLoading: companiesLoading, error: companiesError } = useGetCompanies(
    { input: query },
    token,
    { queryKey: [query, lastLoaded] }
  );

  // Refresh company data
  const refresh = React.useCallback((): void => {
    const now = new Date().getTime();
    setLastLoaded(now);
  }, []);

  return {
    companies,
    loading: companiesLoading || false,
    error: companiesError || null,
    refresh,
  };
}

export function useCompany(providedCompanyID?: number): CompanyResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const defaultCompanyID = user?.companyID?.toString() || '';
  const companyID = providedCompanyID?.toString() || defaultCompanyID;

  // Fetch company data; use react-query to invalidate cache as appropriate
  const { data: company, isLoading: companyLoading, error: getCompanyError } = useGetCompany(
    { companyID },
    token,
    { queryKey: [companyID], enabled: !!companyID && !!token }
  );

  const refresh = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getCompany'] });
  };

  const { mutate: updateCompany } = useUpdateCompany({
    onSuccess: () => {
      refresh();
    },
    onError: (error: Error) => {
      logger.error(error);
    },
  });
  const update = (newCompany: Company): void => {
    updateCompany({ companyID }, newCompany, token);
  };

  return {
    company,
    loading: companyLoading || false,
    error: getCompanyError || null,
    refresh,
    update,
  };
}
