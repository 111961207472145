
import type { IntegrationStatus } from '@sity-ai/types';
import type { QBOConfig, QBOSyncHistory } from '@sity-ai/types';

export interface InstalledIntegration {
  id: string;
  installedAt: string;
  lastSyncAt?: string;
  status: IntegrationStatus;
  config?: Record<string, unknown>;
  error?: {
    code: string;
    message: string;
  };
  syncStatus?: {
    inProgress: boolean;
    lastSync?: string;
    nextSync?: string;
    stats?: {
      customers: { synced: number; total: number };
      invoices: { synced: number; total: number };
      payments: { synced: number; total: number };
    };
  };
}

// Mock installed integrations for the current user
export const MOCK_USER_INTEGRATIONS: Record<string, InstalledIntegration> = {
  quickbooks: {
    id: 'quickbooks',
    installedAt: '2024-03-19T15:00:00Z',
    lastSyncAt: '2024-03-20T14:30:00Z',
    status: 'active',
    config: {
      sync: {
        frequency: 'daily',
        entities: {
          customers: true,
          invoices: true,
          payments: true,
        },
      },
      defaults: {
        taxRate: 'standard',
      },
    } as QBOConfig,
    syncStatus: {
      inProgress: false,
      lastSync: '2024-03-20T14:30:00Z',
      nextSync: '2024-03-21T14:30:00Z',
      stats: {
        customers: { synced: 245, total: 250 },
        invoices: { synced: 1250, total: 1250 },
        payments: { synced: 890, total: 890 },
      },
    },
  },
  // Example of an integration with an error
  xero: {
    id: 'xero',
    installedAt: '2024-03-15T10:00:00Z',
    lastSyncAt: '2024-03-19T09:45:00Z',
    status: 'active',
    error: {
      code: 'AUTH_ERROR',
      message: 'Authentication token expired. Please reconnect your Xero account.',
    },
  },
  // Example of a disconnected integration
  companycam: {
    id: 'companycam',
    installedAt: '2024-03-10T08:00:00Z',
    status: 'disconnected',
  },
};

export const MOCK_QBO_SYNC_HISTORY: QBOSyncHistory = {
  events: [
    {
      id: '1',
      item: 'Spring Cleanup for J. Smith',
      type: 'Service',
      status: 'Synced',
      timestamp: '2024-03-20T18:06:00Z',
    },
    {
      id: '2',
      item: 'INV-1234 for Johnson Property',
      type: 'Invoice',
      status: 'Error',
      timestamp: '2024-03-20T17:44:00Z',
      details: 'Invalid tax code: OUTDOOR-SERVICE not found in QuickBooks',
    },
    {
      id: '3',
      item: 'Payment #PAY-789 - Emily Williams',
      type: 'Payment',
      status: 'Synced with warning',
      timestamp: '2024-03-20T16:32:00Z',
      details: 'Payment processed but customer memo was truncated',
    },
    {
      id: '4',
      item: 'Weekly Maintenance Package',
      type: 'Service',
      status: 'Synced',
      timestamp: '2024-03-20T15:15:00Z',
    },
    {
      id: '5',
      item: 'Robert Martinez',
      type: 'Client',
      status: 'Error',
      timestamp: '2024-03-20T14:23:00Z',
      details: 'Duplicate customer found in QuickBooks with different tax settings',
    },
    {
      id: '6',
      item: 'Monthly Landscaping Subscription',
      type: 'Service',
      status: 'Synced with warning',
      timestamp: '2024-03-20T13:45:00Z',
      details: 'Service class mapping defaulted to General Services',
    },
    {
      id: '7',
      item: 'INV-1235 for Martinez Residence',
      type: 'Invoice',
      status: 'Synced',
      timestamp: '2024-03-20T12:30:00Z',
    },
    {
      id: '8',
      item: 'Sarah Thompson',
      type: 'Client',
      status: 'Error',
      timestamp: '2024-03-20T11:15:00Z',
      details: 'Missing required field: billing address postal code',
    },
    {
      id: '9',
      item: 'Bulk Payment Import March 2024',
      type: 'Payment',
      status: 'Synced with warning',
      timestamp: '2024-03-20T10:05:00Z',
      details: '2 of 15 payments had mismatched amounts, manual review required',
    },
    {
      id: '10',
      item: 'Custom Irrigation Service',
      type: 'Service',
      status: 'Error',
      timestamp: '2024-03-20T09:30:00Z',
      details: 'Service rate not found in QuickBooks item list',
    },
  ],
  summary: {
    total: 432,
    errors: 4,
    warnings: 3,
  },
};

export const MOCK_QBO_SYNC_STATS = {
  today: {
    total: 45,
    successful: 38,
    errors: 4,
    warnings: 3,
  },
  byType: {
    Client: {
      synced: 12,
      failed: 2,
    },
    Invoice: {
      synced: 15,
      failed: 1,
    },
    Payment: {
      synced: 8,
      warnings: 2,
    },
    Service: {
      synced: 3,
      failed: 1,
      warnings: 1,
    },
  },
  lastFullSync: '2024-03-20T00:00:00Z',
  nextScheduledSync: '2024-03-21T00:00:00Z',
};

// Additional mock data for sync filters
export const SYNC_TYPE_OPTIONS = [
  { label: 'All Types', value: 'all' },
  { label: 'Clients', value: 'Client' },
  { label: 'Invoices', value: 'Invoice' },
  { label: 'Payments', value: 'Payment' },
  { label: 'Services', value: 'Service' },
];

export const SYNC_STATUS_OPTIONS = [
  { label: 'All Statuses', value: 'all' },
  { label: 'Synced', value: 'Synced' },
  { label: 'Error', value: 'Error' },
  { label: 'Warning', value: 'Synced with warning' },
];
