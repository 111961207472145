import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useGetTaxRates } from '@sity-ai/api';

import type { TaxRate } from '@sity-ai/types';
import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { logger } from '@/lib/default-logger';

interface TaxRatesResponse {
  taxRates: TaxRate[];
  loading: boolean;
  error: Error | null;
  refreshtaxRatesList: () => void;
}

export function useTaxRates(): TaxRatesResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  // Fetch tax rates
  const { data: taxRates = [], isLoading: loading, error } = useGetTaxRates(
    { companyID },
    token,
    {
      enabled: !!companyID && !!token,
      queryKey: [companyID, token],
    },
  );

  // Log errors
  React.useEffect(() => {
    if (error) logger.error(error);
  }, [error]);

  // Imperatively refresh tax rates list
  const refreshtaxRatesList = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getTaxRates'] });
  };

  return {
    taxRates,
    loading,
    error,
    refreshtaxRatesList,
  };
}
