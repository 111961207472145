
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { ButtonProps } from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export interface IntegrationConnectionCardProps {
  actionLabel: string;
  buttonVariant?: ButtonProps['variant'];
  description: string;
  onAction: () => void;
  title: string;
}

export function IntegrationConnectionCard({
  actionLabel,
  buttonVariant = 'contained',
  description,
  onAction,
  title,
}: IntegrationConnectionCardProps): React.JSX.Element {
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CardContent sx={{ flex: '1 1 auto' }}>
        <Typography gutterBottom variant="h6">
          {title}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {description}
        </Typography>
      </CardContent>
      <CardActions sx={{ p: 2, pt: 0 }}>
        <Box sx={{ width: '100%' }}>
          <Button fullWidth onClick={onAction} size="large" variant={buttonVariant}>
            {actionLabel}
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}
