
// import { ConnectStep } from '@/components/integrations/install/steps/connect-step';
import { FinalizeStep } from '@/components/integrations/install/steps/finalize-step';
import type { IntegrationWizardConfig } from '@/components/integrations/install/wizard';

import { QuickbooksConfigurationStep } from './components/configuration-step';
import { EnhancedWelcomeStep } from './components/configuration/enhanced-welcome-step';

export const quickbooksConfig: IntegrationWizardConfig = {
  key: 'quickbooks',
  title: 'QuickBooks Online',
  overline: 'Integration',
  steps: [
    {
      id: 'welcome',
      label: 'Connect',
      component: EnhancedWelcomeStep,
      content: {
        title: 'Connect Your Account',
        subtext: "We'll redirect you to authorize the integration.",
      },
    },
    {
      id: 'configure',
      label: 'Configure',
      component: QuickbooksConfigurationStep,
      content: {
        title: 'Configure Sync Settings',
        subtext: 'Set up how you want QuickBooks to work with Fieldsity.',
      },
      validateStep: async (values: Record<string, unknown>) => {
        // Basic validation - check if at least one sync option is enabled
        const syncConfig = values?.syncConfiguration || {};
        const hasEnabledSync = Object.values(syncConfig).some(Boolean);
        if (!hasEnabledSync) {
          throw new Error('Please enable at least one sync option');
        }
        return true;
      },
    },
    // {
    //   id: 'connect',
    //   label: 'Connect',
    //   component: ConnectStep,
    //   content: {
    //     title: 'Connect Your Account',
    //     subtext: 'You will be redirected to QuickBooks to authorize the connection.',
    //   },
    // },
    {
      id: 'finalize',
      label: 'Finalize',
      component: FinalizeStep,
      content: {
        title: 'Setup Complete!',
        subtext: 'Your QuickBooks account is now connected to Fieldsity.',
      },
    },
  ],
  onComplete: async (data: Record<string, unknown>): Promise<void> => {
    const syncConfig = data.syncConfiguration || {};
    // Here we'll handle the integration completion
    // For example, save the sync configuration to the backend
  },
};
