import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { paths } from '@/paths';
import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { Loading } from '@/components/core/loading';

interface ProtectedPageProps {
  children: React.ReactNode;
  requiredLevels: number[];
}

export function ProtectedPage({ children, requiredLevels }: ProtectedPageProps): React.ReactElement {
  const { roles, isLoading } = useUserContext();

  // Return loading state if we're still fetching user data
  if (isLoading) return <Loading />;

  const hasRequiredLevel = React.useMemo(() => {
    if (!roles.length) return false;
    return roles.some((role) => requiredLevels.includes(role));
  }, [roles, requiredLevels]);

  if (!isLoading && !hasRequiredLevel) {
    if (roles.length === 0) return <Navigate replace to={paths.auth.auth0.signIn} />;
    return <Navigate replace to={paths.notAuthorized} />;
  }

  return <>{children}</>;
}
