
import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import type { NewPayment, Payment } from '@sity-ai/types';
import { useCreatePayment, useDeletePayment, useGetPayments, useGetPaymentsByClientId, useUpdatePayment } from '@sity-ai/api';

import { logger } from '@/lib/default-logger';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface PaymentsResponse {
  payments: Payment[];
  loading: boolean;
  error: Error | null;
  refreshPaymentsList: () => void;
  createNewPayment: (paymentData: NewPayment) => Promise<void>;
  updateExistingPayment: (paymentID: string, paymentData: Payment) => Promise<void>;
  deleteExistingPayment: (paymentID: string) => Promise<void>;
}

export function usePayments(cid?: number): PaymentsResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() ?? '';
  const clientID = cid?.toString() ?? '';

  // Fetch payments
  const { data: payments, isLoading: loadingByCompanyId, error: errorByCompanyId } = useGetPayments(
    { companyID },
    token,
    {
      queryKey: [companyID, token],
      enabled: !!companyID && !!token && !clientID,
      onError: (error: Error) => { logger.error(error); },
    }
  );

  // Fetch payments by client ID
  const { data: paymentsByClientId, isLoading: loadingByClientId, error: errorByClientId } = useGetPaymentsByClientId(
    { companyID, clientID },
    token,
    {
      queryKey: [companyID, clientID, token],
      enabled: !!companyID && !!token && !!clientID,
      onError: (error: Error) => { logger.error(error); },
    }
  );

  // Imperatively refresh payment list
  const refreshPaymentsList = React.useCallback((): void => {
    queryClient.invalidateQueries({ queryKey: ['getPayments'] });
    queryClient.invalidateQueries({ queryKey: ['getPaymentsByClientId'] });
  }, []);

  // Imperatively create new payment
  const { mutate: handleCreateNewPayment, isLoading: createNewPaymentLoading, error: createNewPaymentError } = useCreatePayment({
    onSuccess: () => { refreshPaymentsList(); },
    onError: (error: Error) => { logger.error(error); },
  });
  const createNewPayment = async (paymentData: NewPayment): Promise<void> => {
    handleCreateNewPayment({ params: paymentData, token });
  };


  // Imperatively update existing payment
  const { mutate: handleUpdateExistingPayment, isLoading: updateExistingPaymentLoading, error: updateExistingPaymentError } = useUpdatePayment({
    onSuccess: () => { refreshPaymentsList(); },
    onError: (error: Error) => { logger.error(error); },
  });
  const updateExistingPayment = async (paymentID: string, paymentData: Payment): Promise<void> => {
    handleUpdateExistingPayment({ params: { paymentID }, taxRate: paymentData, token });
  };

  // Imperatively delete existing payment
  const { mutate: handleDeleteExistingPayment, isLoading: deleteExistingPaymentLoading, error: deleteExistingPaymentError } = useDeletePayment({
    onSuccess: () => { refreshPaymentsList(); },
    onError: (error: Error) => { logger.error(error); },
  });
  const deleteExistingPayment = async (paymentID: string): Promise<void> => {
    handleDeleteExistingPayment({ params: { companyID, paymentID }, token });
  };

  return {
    payments: companyID
      ? !clientID ? payments : paymentsByClientId
      : [],
    loading: loadingByCompanyId || loadingByClientId || createNewPaymentLoading || updateExistingPaymentLoading || deleteExistingPaymentLoading,
    error: !companyID ? new Error('No company ID available') : errorByCompanyId || errorByClientId || createNewPaymentError || updateExistingPaymentError || deleteExistingPaymentError,
    refreshPaymentsList,
    createNewPayment,
    updateExistingPayment,
    deleteExistingPayment,
  };
}
