import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useGetClientAndProperty, useDeleteClient } from '@sity-ai/api';
import type { Client } from '@sity-ai/types';

import { logger } from '@/lib/default-logger';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface ClientsResponse {
  clients: Client[];
  deleteClient: (client: Client, onSuccessCallback?: () => void) => Promise<void>;
  deleteClients: (clientList: Client[], onSuccessCallback?: () => void) => Promise<void>;
  error: Error | null;
  loading: boolean;
  refreshClientList: () => void;
}

export function useClients(): ClientsResponse {
  const { user, token } = useUserContext();
  const queryClient = useQueryClient();
  const companyID = user?.companyID?.toString() || '';

  // Fetch clients query
  const { data: clients = [], isLoading: fetchingClients, error: fetchClientsError } = useGetClientAndProperty(
    { companyID },
    token,
    { queryKey: [companyID, token], enabled: !!companyID && !!token }
  );

  // Imperatively refresh client list
  const refreshClientList = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getClientAndProperty'] });
  };

  // Delete client mutation
  const { mutate: handleDeleteClient, isLoading: deleteClientLoading, error: deleteClientError } = useDeleteClient({
    onSuccess: (_: unknown, variables: { onSuccessCallback?: () => void }) => {
      if (variables.onSuccessCallback) variables.onSuccessCallback();
    },
    onError: (error: Error) => {
      logger.error(error);
    },
  });

  // Delete a single client
  const deleteClient = async (client: Client, onSuccessCallback?: () => void): Promise<void> => {
    handleDeleteClient({
      params: { clientID: client.clientID.toString(), companyID },
      token,
      onSuccessCallback,
    });
  };

  // Delete multiple clients
  const deleteClients = async (clientList: Client[], onSuccessCallback?: () => void): Promise<void> => {
    await Promise.all(clientList.map((client) => deleteClient(client, onSuccessCallback)));
  };

  return {
    clients,
    deleteClient,
    deleteClients,
    error: fetchClientsError || deleteClientError,
    loading: fetchingClients || deleteClientLoading,
    refreshClientList,
  };
}
