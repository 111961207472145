'use client';

import * as React from 'react';

import type { Client } from '@sity-ai/types';
import { useClients } from '@/hooks/use-clients';

export interface ClientContextValue {
  clients: Client[];
  deleteClient: (client: Client, onSuccessCallback?: () => void) => Promise<void>;
  deleteClients: (clientList: Client[], onSuccessCallback?: () => void) => Promise<void>;
  refreshClientList: () => void;
  loading: boolean;
}

export interface ClientProviderProps {
  children: React.ReactNode;
}

export const ClientsContext = React.createContext<ClientContextValue>({
  clients: [],
  deleteClient: async () => undefined,
  deleteClients: async () => undefined,
  refreshClientList: () => undefined,
  loading: false,
});

export function useClientsContext(): ClientContextValue {
  return React.useContext(ClientsContext);
}

export function ClientsProvider({ children }: ClientProviderProps): React.JSX.Element {
  const { clients, deleteClient, deleteClients, loading, refreshClientList } = useClients();

  return (
    <ClientsContext.Provider
      value={{
        clients,
        deleteClient,
        deleteClients,
        refreshClientList,
        loading,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
}
