
import type { Components } from '@mui/material/styles';

import type { Theme } from '../types';

export const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : theme.palette.background.default,
      padding: theme.spacing(4),
      marginTop: 0,
      '&:first-of-type': {
        paddingTop: theme.spacing(6),
      },
    }),
  },
};
