// src/hooks/use-pricebook-items.ts
import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import type { PricebookItem } from '@sity-ai/types';
import { useGetPricebookItems } from '@sity-ai/api';

import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { logger } from '@/lib/default-logger';

interface PricebookItemsResponse {
  pricebookItems: PricebookItem[];
  loading: boolean;
  error: Error | null;
  refreshPricebookItems: () => void;
}

export function usePricebookItems(requestedPricebookItems?: number[]): PricebookItemsResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  // Fetch pricebook items
  const {
    data: allPricebookItems = [],
    isLoading: loading,
    error: allPricebookItemsError,
  } = useGetPricebookItems({ companyID }, token, {
    enabled: !!companyID && !!token,
    queryKey: [companyID, JSON.stringify(requestedPricebookItems), token],
  });

  // Log errors
  React.useEffect(() => {
    if (allPricebookItemsError) logger.error(allPricebookItemsError);
  }, [allPricebookItemsError]);

  // Memoize pricebook items
  const { pricebookItems, error } = React.useMemo(() => {
    if (allPricebookItems.length === 0) {
      return { pricebookItems: [], error: null };
    }

    if (requestedPricebookItems?.length) {
      const filteredItems = allPricebookItems.filter((item: PricebookItem) =>
        requestedPricebookItems.includes(item.pricebookItemID)
      );

      if (filteredItems.length === 0) {
        return { pricebookItems: [], error: new Error('Requested items not found') };
      }

      return { pricebookItems: filteredItems, error: null };
    }

    return { pricebookItems: allPricebookItems, error: null };
  }, [allPricebookItems, requestedPricebookItems]);

  // Imperatively refresh pricebook items
  const refreshPricebookItems = React.useCallback((): void => {
    queryClient.invalidateQueries({ queryKey: ['getPricebookItems'] });
  }, [queryClient]);

  return {
    pricebookItems,
    loading,
    error: allPricebookItemsError || error,
    refreshPricebookItems,
  };
}
