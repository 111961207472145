import * as React from 'react';
import type { Company } from '@sity-ai/types';
import type { StripeCustomerSubscription } from '@sity-ai/api';
import { useGetStripeSubscriptionDetails  } from '@sity-ai/api';

import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { logger } from '@/lib/default-logger';

interface Subscription {
  subscription: StripeCustomerSubscription | null;
  loading: boolean;
  loaded: boolean;
  error: Error | null;
}

export function useStripeSubscription(company: Company | null): Subscription {
  const { token } = useUserContext();
  const stripeCustomerId = company?.stripeCustomerID ?? '';
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const { data: subscription, isLoading: loading, error: error } = useGetStripeSubscriptionDetails (
    { stripeCustomerId },
    token,
    {
      enabled: !!stripeCustomerId && !!token,
      onError: (error: Error) => { logger.error(error); },
      onSuccess: () => { setLoaded(true); },
    }
  );

  return { subscription, loading, loaded, error };
}
