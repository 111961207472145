
import * as React from 'react';

import type { Payment } from '@sity-ai/types';
import { usePayments } from '@/hooks/use-payments';
import { Loading } from '@/components/core/loading';

export interface PaymentsContextValue {
  payments: Payment[];
  loading: boolean;
  error: Error | null;
  refreshPaymentsList: () => void;
  createNewPayment: (paymentData: Omit<Payment, 'paymentID'>) => Promise<void>;
  updateExistingPayment: (paymentID: string, paymentData: Payment) => Promise<void>;
  deleteExistingPayment: (paymentID: string) => Promise<void>;
}

export interface PaymentsProviderProps {
  children: React.ReactNode;
  clientID?: string;
}

// Context Exports
export const PaymentsContext = React.createContext<PaymentsContextValue>({
  payments: [],
  loading: false,
  error: null,
  refreshPaymentsList: () => undefined,
  createNewPayment: async () => Promise.resolve(),
  updateExistingPayment: async () => Promise.resolve(),
  deleteExistingPayment: async () => Promise.resolve(),
});

export function usePaymentsContext(): PaymentsContextValue {
  return React.useContext(PaymentsContext);
}

export function PaymentsProvider({ children, clientID }: PaymentsProviderProps): React.JSX.Element {
  const numericClientID = clientID ? parseInt(clientID, 10) : undefined;
  const {
    payments,
    loading,
    error,
    refreshPaymentsList,
    createNewPayment,
    updateExistingPayment,
    deleteExistingPayment,
  } = usePayments(numericClientID);

  return (
    <PaymentsContext.Provider
      value={{
        payments,
        loading,
        error,
        refreshPaymentsList,
        createNewPayment,
        updateExistingPayment,
        deleteExistingPayment,
      }}
    >
      {loading ? <Loading /> : children}
    </PaymentsContext.Provider>
  );
}
