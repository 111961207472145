'use client';

import * as React from 'react';

import type { TaxRate } from '@sity-ai/types';
import { useTaxRates } from '@/hooks/use-tax-rates';
import { Loading } from '@/components/core/loading';

export interface TaxRatesContextValue {
  taxRates: TaxRate[];
  refreshtaxRatesList: () => void;
}

export interface TaxRatesProviderProps {
  children: React.ReactNode;
}

// Context Exports
export const TaxRatesContext = React.createContext<TaxRatesContextValue>({
  taxRates: [],
  refreshtaxRatesList: () => undefined,
});

export function useTaxRatesContext(): TaxRatesContextValue {
  return React.useContext(TaxRatesContext);
}

export function TaxRatesProvider({ children }: TaxRatesProviderProps): React.JSX.Element {
  const { taxRates, loading, refreshtaxRatesList } = useTaxRates();
  return (
    <TaxRatesContext.Provider value={{ taxRates, refreshtaxRatesList }}>
      {loading ? <Loading /> : children}
    </TaxRatesContext.Provider>
  );
}
