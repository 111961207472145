import * as React from 'react';
import { useQboDetails, useQboDisconnect, useQboConfigUpdate, useQboStatus, useQboAuthorization, useQboSyncClients } from '@sity-ai/api';
import type { QBOConfig, QBOConnectionStatus, QBOSyncHistory, QBOSyncStatus } from '@sity-ai/types';

import { logger } from '@/lib/default-logger';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface OAuthCallbackParams {
  code: string;
  state: string;
  realmId: string;
}

interface QuickbooksContextValue {
  config: QBOConfig | null;
  connection: QBOConnectionStatus;
  syncStatus: QBOSyncStatus;
  syncHistory: QBOSyncHistory;
  updateConfig: (config: QBOConfig) => Promise<void>;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
  syncNow: () => Promise<void>;
  handleOAuthCallback: (params: OAuthCallbackParams) => void;
  loading: boolean;
}

const QuickbooksContext = React.createContext<QuickbooksContextValue | undefined>(undefined);

interface QuickbooksProviderProps {
  children: React.ReactNode;
}

export function QuickbooksProvider({ children }: QuickbooksProviderProps): React.JSX.Element {
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  const [config, setConfig] = React.useState<QBOConfig | null>(null);
  const [connection, setConnection] = React.useState<QBOConnectionStatus>({ isConnected: false });
  const [syncStatus, setSyncStatus] = React.useState<QBOSyncStatus>({ inProgress: false });
  const [syncHistory, setSyncHistory] = React.useState<QBOSyncHistory>({
    events: [],
    summary: { total: 0, errors: 0, warnings: 0 },
  });
  const [code, setCode] = React.useState<string>('');
  const [state, setState] = React.useState<string>('');
  const [realmId, setRealmId] = React.useState<string>('');

  /****************************************************************************
   * QBO Async Operations
   ****************************************************************************/

  // Fetch QBO status
  const {
    data: qboStatus,
    isLoading: qboStatusLoading,
    error: qboStatusError
  } = useQboStatus({ companyID }, token, {
    enabled: !!companyID && !!token,
    queryKey: [companyID, token]
  });

  // Fetch QBO details
  const {
    data: qboDetails,
    isLoading: qboDetailsLoading,
    error: qboDetailsError
  } = useQboDetails({ companyID }, token, {
    enabled: !!companyID && !!token,
    queryKey: [companyID, token],
  });

  // Disconnect from QBO hook
  const { mutate: disconnectQbo } = useQboDisconnect({ companyID });

  // Update QBO config hook
  const { mutate: updateQboConfig } = useQboConfigUpdate({ companyID });

  // Sync clients hook
  const { mutate: syncClients } = useQboSyncClients({ companyID });

  // Handle OAuth callback hook
  const { isLoading: authorizeLoading, error: authorizeError } = useQboAuthorization(
    { companyID, code, state, realmId },
    token,
    {
      enabled: [companyID, token, code, state, realmId].every((value) => !!value),
      queryKey: [companyID, token, code, state, realmId]
    }
  );

  /****************************************************************************
   * QBO Handlers
   ****************************************************************************/

  const connect = React.useCallback(async (): Promise<void> => {
    try {
      const url = `${import.meta.env.VITE_QUICKBOOKS_AUTH_URL}&companyId=${companyID}`;

      const width = 600;
      const height = 700;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;

      const popup = window.open(
        url,
        'Connect to QuickBooks',
        `width=${width},height=${height},left=${left},top=${top},toolbar=no,menubar=no`
      );

      if (!popup) {
        throw new Error('Popup blocked - please enable popups for this site');
      }

      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer);
        }
      }, 500);
    } catch (err) {
      logger.error('Error initiating QBO connection:', err);
      throw new Error('Failed to initiate connection');
    }
  }, [companyID]);

  // Disconnect from QBO handler
  const disconnect = async (): Promise<void> => {
    try {
      await disconnectQbo({ params: { companyID }, token });
      setConnection({ isConnected: false });
      setConfig(null);
    } catch (err) {
      logger.error('Error disconnecting QBO:', err);
      throw new Error('Failed to disconnect');
    };
  };

  // Update QBO config handler
  const updateConfig = async (newConfig: QBOConfig): Promise<void> => {
    try {
      await updateQboConfig({ params: { companyID }, config: newConfig, token });
      setConfig(newConfig);
    } catch (err) {
      logger.error('Error updating QBO config:', err);
      throw new Error('Failed to update configuration');
    }
  };

  // Handle OAuth callback handler
  const handleOAuthCallback = (params: OAuthCallbackParams): void => {
    setCode(params.code);
    setState(params.state);
    setRealmId(params.realmId);
  };

  // Sync clients handler
  const syncNow = React.useCallback(async (): Promise<void> => {
    try {
      await syncClients({ params: { companyID, createdBy: user?.userID }, token });
      setSyncStatus({ inProgress: true });
    } catch (err) {
      logger.error('Error initiating QBO sync:', err);
      throw new Error('Failed to initiate sync');
    }
  }, [companyID, token, user?.userID]);

  /****************************************************************************
   * QBO Lifecycle Hooks
   ****************************************************************************/

  // Set the context values
  React.useEffect(() => {
    if (qboDetails) {
      setConfig(qboDetails.config);
      setConnection(qboDetails.connection);
      setSyncStatus(qboDetails.syncStatus);
      setSyncHistory(qboDetails.syncHistory);
    }
  }, [qboDetails]);

  // Log errors
  React.useEffect(() => {
    if (qboStatusError) {
      logger.error(qboStatusError);
      setConnection((prev) => ({ ...prev, error: 'Failed to connect to QBO' }));
    }
    if (qboDetailsError) {
      logger.error(qboDetailsError);
      setConnection((prev) => ({ ...prev, error: 'Failed to fetch QBO details' }));
    }
    if (authorizeError) {
      logger.error(authorizeError);
      setConnection((prev) => ({ ...prev, error: 'Failed to authorize' }));
    }
  }, [qboStatusError, qboDetailsError, authorizeError]);

  const value = React.useMemo(
    () => ({
      config,
      connection,
      syncStatus,
      syncHistory,
      updateConfig,
      connect,
      disconnect,
      syncNow,
      handleOAuthCallback,
      loading: qboStatusLoading || qboDetailsLoading || authorizeLoading,
    }),
    [config, connection, syncStatus, syncHistory, updateConfig, connect, disconnect, syncNow, handleOAuthCallback, qboStatusLoading, qboDetailsLoading, authorizeLoading]
  );

  return <QuickbooksContext.Provider value={value}>{children}</QuickbooksContext.Provider>;
}

export function useQuickbooks(): QuickbooksContextValue {
  const context = React.useContext(QuickbooksContext);
  if (context === undefined) {
    throw new Error('useQuickbooks must be used within a QuickbooksProvider');
  }
  return context;
}
