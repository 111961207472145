// root.tsx
import * as React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntercomProvider } from 'react-use-intercom';

import '@/styles/global.css';

import type { Metadata } from '@sity-ai/types';
import { config } from '@/config';
import { applyDefaultSettings } from '@/lib/settings/apply-default-settings';
import { getSettings as getPersistedSettings } from '@/lib/settings/get-settings';
import { UserProvider } from '@/contexts/auth/user-context';
import { CompanyProvider } from '@/contexts/company-context';
import { SettingsProvider } from '@/contexts/settings';
import { Analytics } from '@/components/core/analytics';
import { I18nProvider } from '@/components/core/i18n-provider';
import { LocalizationProvider } from '@/components/core/localization-provider';
import { ThemeProvider } from '@/components/core/theme-provider/theme-provider';
import { Toaster } from '@/components/core/toaster';

import { FlagProvider } from './contexts/flag-context';

// import { IntegrationsProvider } from './contexts/integrations-context';
// import { InvoicesProvider } from './contexts/invoices-context';
// import { PaymentsProvider } from './contexts/payments-context';
// import { PricebookItemsProvider } from './contexts/pricebook-items-context';
// import { SchedulingProvider } from './contexts/scheduling-context';

const metadata: Metadata = { title: config.site.name };

interface RootProps {
  children: React.ReactNode;
}

// root.tsx
export function Root({ children }: RootProps): React.JSX.Element {
  const settings = React.useRef(applyDefaultSettings(getPersistedSettings()));

  return (
    <HelmetProvider>
      <Helmet>
        <meta content={config.site.themeColor} name="theme-color" />
        <title>{metadata.title}</title>
      </Helmet>
      <Analytics>
        <LocalizationProvider>
          <UserProvider>
            <IntercomProvider appId={`${import.meta.env.VITE_INTERCOM_KEY}`}>
              <FlagProvider>
                <SettingsProvider settings={settings.current}>
                  <I18nProvider language="en">
                    <CompanyProvider>
                      <ThemeProvider>
                        {children}
                        <Toaster />
                      </ThemeProvider>
                    </CompanyProvider>
                  </I18nProvider>
                </SettingsProvider>
              </FlagProvider>
            </IntercomProvider>
          </UserProvider>
        </LocalizationProvider>
      </Analytics>
    </HelmetProvider>
  );
}
