// src/hooks/use-pricebooks.ts
import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useGetPricebooks } from '@sity-ai/api';
import type { Pricebook } from '@sity-ai/types';

import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { logger } from '@/lib/default-logger';

interface PricebooksResponse {
  pricebooks: Pricebook[];
  loading: boolean;
  error: Error | null;
  refreshPricebooks: () => void;
}

export function usePricebooks(): PricebooksResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  // Fetch pricebooks
  const { data: pricebooks, isLoading: loading, error: error } = useGetPricebooks({ companyID }, token, {
    enabled: !!companyID && !!token,
    queryKey: [companyID, token],
  });

  // Log error
  React.useEffect(() => {
    if (error) logger.error(error);
  }, [error]);

  // Imperatively refresh pricebook list
  const refreshPricebookList = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getPricebooks'] });
  };

  return {
    pricebooks,
    loading,
    error,
    refreshPricebooks: refreshPricebookList,
  };
}
