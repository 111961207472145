
import type { SyncConfigurationItem } from '@/components/integrations/common/sync/types';

interface QBOConfigData {
  clients: SyncConfigurationItem[];
  products: SyncConfigurationItem[];
  invoices: SyncConfigurationItem[];
  payments: SyncConfigurationItem[];
}

export const QBO_CONFIG_DATA: QBOConfigData = {
  clients: [
    {
      id: 'qbo-import-clients',
      label: 'One-time import from QBO to Fieldsity',
      description: 'Import all existing clients from QuickBooks Online to Fieldsity',
      direction: {
        type: 'one-way',
        from: 'provider',
        to: 'fieldsity',
      },
      enabled: true,
      entityType: 'client',
    },
    {
      id: 'qbo-sync-clients',
      label: 'Ongoing sync when added or updated',
      description: 'Automatically sync new and updated clients between systems',
      direction: {
        type: 'two-way',
        from: 'fieldsity',
        to: 'provider',
      },
      enabled: false,
      entityType: 'client',
    },
  ],
  products: [
    {
      id: 'qbo-import-products',
      label: 'One-time import from QBO to Fieldsity',
      description: 'Import all existing products and services from QuickBooks Online',
      direction: {
        type: 'one-way',
        from: 'provider',
        to: 'fieldsity',
      },
      enabled: false,
      entityType: 'product',
    },
    {
      id: 'qbo-sync-products',
      label: 'Ongoing sync when added or updated',
      description: 'Automatically sync new and updated products between systems',
      direction: {
        type: 'two-way',
        from: 'fieldsity',
        to: 'provider',
      },
      enabled: false,
      entityType: 'product',
    },
  ],
  invoices: [
    {
      id: 'qbo-push-invoices',
      label: 'Push invoices from Fieldsity to QBO',
      description: 'Automatically push new invoices to QuickBooks Online when created',
      direction: {
        type: 'one-way',
        from: 'fieldsity',
        to: 'provider',
      },
      enabled: false,
      entityType: 'invoice',
    },
    {
      id: 'qbo-sync-invoice-status',
      label: 'Sync "Paid" status in QBO sync back to Fieldsity',
      description: 'Keep invoice payment status in sync between systems',
      direction: {
        type: 'two-way',
        from: 'provider',
        to: 'fieldsity',
      },
      enabled: false,
      entityType: 'invoice',
    },
  ],
  payments: [
    {
      id: 'qbo-sync-payments',
      label: 'Sync payments between systems',
      description: 'Keep payment records synchronized between Fieldsity and QuickBooks',
      direction: {
        type: 'two-way',
        from: 'fieldsity',
        to: 'provider',
      },
      enabled: false,
      entityType: 'payment',
    },
  ],
};

export const {
  clients: QBO_CLIENT_CONFIG,
  products: QBO_PRODUCT_CONFIG,
  invoices: QBO_INVOICE_CONFIG,
  payments: QBO_PAYMENT_CONFIG,
} = QBO_CONFIG_DATA;
