// components/core/scheduling/scheduling-field.tsx

import * as React from 'react';
import type { ModuleType } from '@/constants/module-colors';
import { getModuleColor } from '@/constants/module-colors';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';

import type { NavItemConfig } from '@sity-ai/types';
import { isNavItemActive } from '@/lib/is-nav-item-active';
import { usePathname } from '@/hooks/use-pathname';
import { DynamicLogo } from '@/components/core/logo';

import { navSections } from './nav-config';
import { icons } from './nav-icons';

const NAV_ITEM_HEIGHT = 56;
const LOGO_SIZE = 40;
const SIDEBAR_WIDTH = 74;

export function UnifiedSidebar(): React.JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const pathname = usePathname();

  const renderNavItem = (item: NavItemConfig): React.ReactElement | null => {
    if (!item.href) return null;

    const IconComponent = icons[item.icon ?? ''];
    const active = isNavItemActive({
      disabled: item.disabled,
      external: item.external,
      href: item.href,
      matcher: item.matcher,
      pathname,
    });

    const moduleColorMain = item.module ? getModuleColor(item.module as ModuleType, 'main') : 'primary.main';
    const moduleColorLightest = item.module ? getModuleColor(item.module as ModuleType, 'lightest') : 'action.hover';

    return (
      <Tooltip key={item.key} placement="right" title={<span style={{ fontSize: '1rem' }}>{item.title}</span>}>
        <ListItem
          button
          onClick={() => {
            navigate(item.href!);
          }}
          sx={{
            minHeight: NAV_ITEM_HEIGHT,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: active ? moduleColorMain : '#acacac',
            backgroundColor: active ? moduleColorLightest : 'transparent',
            paddingLeft: '14px',
            paddingY: 1.5,
            position: 'relative',
            ':hover': {
              backgroundColor: moduleColorLightest,
              color: moduleColorMain,
              '& .MuiListItemIcon-root': {
                color: moduleColorMain,
              },
            },
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '5px',
              height: '100%',
              backgroundColor: active ? moduleColorMain : 'transparent',
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: active ? moduleColorMain : '#acacac',
              justifyContent: 'center',
              // minWidth: 'auto',
              marginBottom: '-4px',
            }}
          >
            {IconComponent ? <IconComponent size={28} /> : null}
          </ListItemIcon>
          {/* Title Text */}
          <Box
            sx={{
              fontSize: '10px',
              textAlign: 'center',
              fontWeight: '600',
              whiteSpace: 'nowrap',

              maxWidth: '100%',
              marginTop: '-8px',
            }}
          >
            {item.title}
          </Box>
        </ListItem>
      </Tooltip>
    );
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: SIDEBAR_WIDTH,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.mode === 'light' ? '#232426' : 'background.paper',
        borderRight: '1px solid',
        borderColor: 'divider',
        alignItems: 'center',
        zIndex: theme.zIndex.drawer,
        paddingY: 0.5,
      }}
    >
      {/* Clickable logo at the top */}
      <Box
        component="button"
        onClick={() => {
          navigate('/');
        }}
        sx={{
          width: '100%',
          height: NAV_ITEM_HEIGHT,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 2,
          border: 'none',
          background: 'transparent',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        <DynamicLogo emblem height={LOGO_SIZE} width={LOGO_SIZE} />
        {/* Optional: Add Logo Title if needed */}
        {/* <Box sx={{ fontSize: '10px', color: 'white', mt: 0.5 }}>Logo Title</Box> */}
      </Box>

      {/* Scrollable navigation items */}
      <Box
        sx={{
          flex: 1,
          width: '100%',
          overflowY: 'auto',
          padding: 0,
        }}
      >
        <List sx={{ padding: 0 }}>
          {navSections.find((section) => section.key === 'main')?.items.map((item) => renderNavItem(item))}
        </List>
      </Box>

      {/* Bottom section items (e.g., settings) */}
      <List sx={{ width: '100%', padding: 0 }}>
        {navSections.find((section) => section.key === 'bottom')?.items.map((item) => renderNavItem(item))}
      </List>
    </Box>
  );
}
