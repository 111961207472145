import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import type { ServiceRecord, Client, Visit } from '@sity-ai/types';
import { useGetClients, useGetServiceRecords, useUpdateJob, useDeleteVisit, useUpdateVisit } from '@sity-ai/api';
import { toast } from 'sonner';

import { logger } from '@/lib/default-logger';
import { dayjs } from '@/lib/dayjs';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface CloseActiveServiceRecordOptions {
  closeJobWithIncompleteVisits?: boolean;
}
const CLOSE_ACTIVE_SERVICE_RECORD_DEFAULT_OPTS = {
  closeJobWithIncompleteVisits: false,
};

interface CompleteActiveServiceRecordOpts {
  deleteFutureVisits?: boolean;
}
const COMPLETE_ACTIVE_SERVICE_RECORD_DEFAULT_OPTS = {
  deleteFutureVisits: false,
};

export interface ActiveServiceRecordActions {
  closeActiveServiceRecord: (opts?: CloseActiveServiceRecordOptions) => boolean;
  archiveActiveServiceRecord: () => boolean;
  removeAllVisitsFromActiveServiceRecord: () => boolean;
  completeAllPastVisitsForActiveServiceRecord: (opts?: CompleteActiveServiceRecordOpts) => boolean;
}

interface ServiceRecordsMethods {
  error: Error | null;
  loading: boolean;
  loaded: boolean;
  refreshServiceRecordList: () => void;
  serviceRecord: ServiceRecord | null;
  serviceRecords: ServiceRecord[];
  setActiveServiceRecord: (jobID: number) => ServiceRecord | null;
  serviceRecordActions: ActiveServiceRecordActions;
}

export function useServiceRecords(): ServiceRecordsMethods {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  const [serviceRecords, setServiceRecords] = React.useState<ServiceRecord[]>([]);
  const [serviceRecord, setServiceRecord] = React.useState<ServiceRecord | null>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  // Get a list of all service records
  const {
    data: allServiceRecordsResponse,
    isLoading: allServiceRecordsLoading,
    error: allServiceRecordsError
  } = useGetServiceRecords(
    { companyID },
    token,
    {
      enabled: !!companyID && !!token,
      queryKey: [companyID, token],
    }
  );

  // Get a list of all clients
  const {
    data: allClientsResponse,
    isLoading: allClientsLoading,
    error: allClientsError
  } = useGetClients(
    { companyID },
    token,
    {
      enabled: !!companyID && !!token,
      queryKey: [companyID, token],
    }
  );

  // Log any errors from async activity
  React.useEffect(() => {
    if (allServiceRecordsError) {
      logger.error(allServiceRecordsError);
      toast.error('Unable to load service records');
    }
    if (allClientsError) {
      logger.error(allClientsError);
      toast.error('Unable to load clients');
    }
  }, [allServiceRecordsError, allClientsError]);

  // Combine service records and clients
  React.useEffect(() => {
    if (!allServiceRecordsResponse || !allClientsResponse) return;

    const combinedRecords = allServiceRecordsResponse.map((sr: ServiceRecord) => {
      const client = allClientsResponse.find((c: Client) => c.clientID === sr.clientID);
      return { ...sr, client } as ServiceRecord;
    });

    setServiceRecords((prevRecords: ServiceRecord[]) => {
      const isSame =
        prevRecords.length === combinedRecords.length &&
        prevRecords.every((record, index) => (
          record.jobID === combinedRecords[index].jobID)
        );

      if (isSame) return prevRecords;
      return combinedRecords;
    });

    setLoaded(true);
  }, [allServiceRecordsResponse, allClientsResponse]);

  // Imperative function to refresh the client/service record list
  const refreshServiceRecordList = (): void => {
    queryClient.invalidateQueries({ queryKey: ['getServiceRecords'] });
    queryClient.invalidateQueries({ queryKey: ['getClients'] });
  };

  // Imperative function to set the active service record
  const setActiveServiceRecord = (jobID: number): ServiceRecord | null => {
    const record = serviceRecords.find((sr) => sr.jobID === jobID)!;
    if (!record) return null;
    setServiceRecord(record);
    return record;
  };

  // Imperative function to close the active service record
  const { mutate: handleCloseServiceRecord, isLoading: closeServiceRecordLoading, error: closeServiceRecordError } = useUpdateJob();
  const closeActiveServiceRecord = (
    opts: CloseActiveServiceRecordOptions = CLOSE_ACTIVE_SERVICE_RECORD_DEFAULT_OPTS
  ): boolean => {
    const allVisitsComplete = serviceRecord?.visits.every((visit) => visit.isComplete);
    if (!allVisitsComplete && !opts.closeJobWithIncompleteVisits) return false;

    handleCloseServiceRecord({ jobID: serviceRecord?.jobID?.toString(), companyID }, { jobStatusID: 6 }, token);
    return true;
  };

  // Imperative function to archive the active service record
  const { mutate: handleArchiveServiceRecord, isLoading: archiveServiceRecordLoading, error: archiveServiceRecordError } = useUpdateJob({
    enabled: !!companyID && !!token,
  });
  const archiveActiveServiceRecord = (): boolean => {
    if (!serviceRecord) return false;
    handleArchiveServiceRecord({ jobID: serviceRecord?.jobID?.toString(), companyID }, { jobStatusID: 7 }, token);
    return true;
  };

  // Imperative function to remove all visits from the active service record
  const { mutate: handleRemoveAllVisitsFromServiceRecord, isLoading: removeAllVisitsFromServiceRecordLoading, error: removeAllVisitsFromServiceRecordError } = useDeleteVisit({
    enabled: !!companyID && !!token,
  });
  const removeAllVisitsFromActiveServiceRecord = (): boolean => {
    if (!serviceRecord) return false;
    handleRemoveAllVisitsFromServiceRecord({ jobID: serviceRecord?.jobID?.toString(), companyID }, token);
    return true;
  };

  // Imperative function to complete all past visits for the active service record
  const { mutate: handleCompleteAllPastVisitsForServiceRecord, isLoading: completeAllPastVisitsForServiceRecordLoading, error: completeAllPastVisitsForServiceRecordError } = useUpdateVisit({
    enabled: !!companyID && !!token,
    onError: (error: Error) => {
      logger.error(error);
      toast.error('Unable to complete visit(s)');
    },
  });
  const { mutate: handleDeleteVisit, isLoading: deleteVisitLoading, error: deleteVisitError } = useDeleteVisit({
    enabled: !!companyID && !!token,
    onError: (error: Error) => {
      logger.error(error);
      toast.error('Unable to delete visit(s)');
    },
  });
  const completeAllPastVisitsForActiveServiceRecord = (opts: CompleteActiveServiceRecordOpts = COMPLETE_ACTIVE_SERVICE_RECORD_DEFAULT_OPTS): boolean => {
    if (!serviceRecord) return false;

    const today = dayjs();
    const pastVisits: Visit[] = [];
    const futureVisits: Visit[] = [];
    serviceRecord.visits.forEach((visit) => {
      if (visit.endDate && dayjs(visit.endDate).isBefore(today)) pastVisits.push(visit);
      else if (opts.deleteFutureVisits) futureVisits.push(visit);
    });

    pastVisits.forEach((visit) => {
      handleCompleteAllPastVisitsForServiceRecord(
        { jobID: serviceRecord?.jobID?.toString(), companyID, visitID: visit.visitID?.toString() },
        { isComplete: true },
        token
      );
    });

    futureVisits.forEach((visit) => {
      handleDeleteVisit({ visitID: visit.visitID?.toString(), companyID }, token);
    });

    return true;
  };

  return {
    error: allServiceRecordsError || allClientsError || closeServiceRecordError || archiveServiceRecordError || removeAllVisitsFromServiceRecordError || completeAllPastVisitsForServiceRecordError || deleteVisitError,
    loaded,
    loading: [allServiceRecordsLoading,
      allClientsLoading,
      closeServiceRecordLoading,
      archiveServiceRecordLoading,
      removeAllVisitsFromServiceRecordLoading,
      completeAllPastVisitsForServiceRecordLoading,
      deleteVisitLoading].some((loading) => loading),
    refreshServiceRecordList,
    serviceRecord,
    serviceRecordActions: {
      closeActiveServiceRecord,
      archiveActiveServiceRecord,
      removeAllVisitsFromActiveServiceRecord,
      completeAllPastVisitsForActiveServiceRecord,
    },
    serviceRecords,
    setActiveServiceRecord,
  };
}
