
import * as React from 'react';

import { WelcomeStep } from '@/components/integrations/install/steps/welcome-step';
import type { WizardStepComponentProps } from '@/components/integrations/install/wizard';

import { QuickBooksConnectionCards } from '../qbo-connection-cards';

export function EnhancedWelcomeStep({ content, stepData }: WizardStepComponentProps): React.JSX.Element {
  return (
    <WelcomeStep
      AdditionalContent={QuickBooksConnectionCards}
      content={content}
      integration={{
        key: 'quickbooks',
        name: 'QuickBooks Online',
      }}
      isProcessing={false}
      stepData={stepData}
    />
  );
}
