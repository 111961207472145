
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import type { WizardStepComponentProps } from '../wizard';

interface WelcomeStepProps extends WizardStepComponentProps {
  integration: {
    key: string;
    name: string;
  };
  AdditionalContent?: React.ComponentType;
}

export function WelcomeStep({ content, integration, AdditionalContent }: WelcomeStepProps): React.JSX.Element {
  const title = content?.title ?? `Connect ${integration.name}`;
  const subtext = content?.subtext ?? `Configure your integration with ${integration.name}`;

  return (
    <Stack spacing={4}>
      <Stack marginBottom={5} spacing={2}>
        <Typography variant="h5">{title}</Typography>
        <Typography color="text.secondary">{subtext}</Typography>
      </Stack>

      {AdditionalContent ? <AdditionalContent /> : null}
    </Stack>
  );
}
