import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useGetInvoices } from '@sity-ai/api';
import type { Invoice } from '@sity-ai/types';

import { logger } from '@/lib/default-logger';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface InvoiceResponse {
  invoices: Invoice[];
  grandTotal: number;
  totalsByJob: Record<string, number>;
  loading: boolean;
  error: Error | null;
  refreshInvoiceList: () => void;
}

export function useInvoices(clientID?: string): InvoiceResponse {
  const queryClient = useQueryClient();
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString();

  // Fetch invoices
  const { data: invoiceResponse = [], isLoading: loading, error } = useGetInvoices(
    { companyID },
    token,
    {
      queryKey: [companyID, clientID, token],
      enabled: !!companyID && !!token
    }
  );

  // Log error
  React.useEffect(() => { if (error) logger.error(error); }, [error]);

  const invoices = React.useMemo(() => {
    return invoiceResponse?.invoicesWithLineItemsAndClientDetails ?? [];
  }, [invoiceResponse]);

  // Calculate grand total
  const grandTotal = React.useMemo(() => {
    if (invoices.length === 0) return 0;
    const filteredInvoices = clientID
      ? invoices.filter((invoice: Invoice) => invoice.clientID?.toString() === clientID)
      : invoices;
    return filteredInvoices.reduce((sum: number, invoice: Invoice) => sum + (invoice.jobTotal ?? 0), 0);
  }, [invoices, clientID]);

  // Imperatively refresh invoice list
  const refreshInvoiceList = React.useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ['getInvoices'] });
  }, []);

  return {
    invoices: invoices.invoicesWithLineItemsAndClientDetails || [],
    error,
    loading,
    refreshInvoiceList,
    totalsByJob: invoices?.jobIDTotals ?? {},
    grandTotal,
  };
}
