

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { User } from '@phosphor-icons/react';
import type { Icon } from '@phosphor-icons/react/dist/lib/types';

import type { NavItemConfig } from '@sity-ai/types';
import { isNavItemActive } from '@/lib/is-nav-item-active';
import { usePathname } from '@/hooks/use-pathname';
import { useUser } from '@/hooks/use-user';
import { RouterLink } from '@/components/core/link';

interface SideNavProps {
  navItems: NavItemConfig[];
  hideUserInfo?: boolean;
  iconsMap: Record<string, Icon>;
}

export function SideNav({ navItems, hideUserInfo = false, iconsMap }: SideNavProps): React.JSX.Element {
  const pathname = usePathname();
  const { user } = useUser();

  return (
    <div>
      <Stack
        spacing={3}
        sx={{
          flex: '0 0 auto',
          flexDirection: { xs: 'column-reverse', md: 'column' },
          position: { md: 'sticky' },
          top: '64px',
          width: { xs: '100%', md: '240px' },
        }}
      >
        <Stack component="ul" spacing={3} sx={{ listStyle: 'none', m: 0, p: 0 }}>
          {navItems.map((group) => (
            <Stack component="li" key={group.key} spacing={2}>
              {group.title ? (
                <div>
                  <Typography color="text.secondary" variant="overline">
                    {group.title}
                  </Typography>
                </div>
              ) : null}
              <Stack component="ul" spacing={1} sx={{ listStyle: 'none', m: 0, p: 0 }}>
                {group.items?.map((item) => <NavItem {...item} icons={iconsMap} key={item.key} pathname={pathname} />)}
              </Stack>
            </Stack>
          ))}
        </Stack>
        {!hideUserInfo && (
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Avatar
              sx={{
                height: 28,
                width: 28,
              }}
            >
              <SvgIcon>
                <User size={24} />
              </SvgIcon>
            </Avatar>
            <div>
              <Typography variant="subtitle1">
                {user?.firstName} {user?.lastName}
              </Typography>
              <Typography color="text.secondary" variant="caption">
                {user?.email}
              </Typography>
            </div>
          </Stack>
        )}
      </Stack>
    </div>
  );
}

interface NavItemProps extends NavItemConfig {
  pathname: string;
  icons: Record<string, Icon>;
}

function NavItem({ disabled, external, href, icon, pathname, title, icons }: NavItemProps): React.JSX.Element {
  const active = isNavItemActive({ disabled, external, href, pathname });
  const Icon = icon ? icons[icon] : null;

  return (
    <Box component="li" sx={{ userSelect: 'none' }}>
      <Box
        {...(href
          ? {
              component: external ? 'a' : RouterLink,
              href,
              target: external ? '_blank' : undefined,
              rel: external ? 'noreferrer' : undefined,
            }
          : { role: 'button' })}
        sx={{
          alignItems: 'center',
          borderRadius: 1,
          color: 'var(--mui-palette-text-secondary)',
          cursor: 'pointer',
          display: 'flex',
          flex: '0 0 auto',
          gap: 1,
          p: '6px 16px',
          textDecoration: 'none',
          whiteSpace: 'nowrap',
          ...(disabled && { color: 'var(--mui-palette-text-disabled)', cursor: 'not-allowed' }),
          ...(active && {
            bgcolor: 'var(--mui-palette-secondary-selected)',
            color: 'var(--mui-palette-primary-secondary)',
          }),
          ...(!active &&
            !disabled && {
              '&:hover': {
                bgcolor: 'var(--mui-palette-action-hover)',
                color: 'var(--mui-palette-text-secondary)',
              },
            }),
        }}
        tabIndex={0}
      >
        {Icon ? (
          <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flex: '0 0 auto' }}>
            <Icon
              fill="currentColor" // Changed this line
              fontSize="var(--icon-fontSize-md)"
              weight={active ? 'fill' : undefined}
            />
          </Box>
        ) : null}
        <Box sx={{ flex: '1 1 auto' }}>
          <Typography
            component="span"
            sx={{ color: 'inherit', fontSize: '0.875rem', fontWeight: 500, lineHeight: '28px' }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
